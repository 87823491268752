<section>
  <div class="content">
    <div class="card">
      <h1 class="salutation">Olá, eu sou a Mariana Bastos!</h1>
      <br>
      <p class="apresentation">
        Sou uma desenvolvedora Front-end e estudante de desenvolvimento Back-end.
        Sou apaixonada por tecnologia, adoro aprender coisas novas e diferentes,
        e amo programar.
      </p>
      <div class="icons">
        <a href="https://github.com/dev-mariana" target="_blank">
          <i class="fab fa-github"></i>
        </a>
        <a href="https://www.linkedin.com/in/dev-mariana/" target="_blank">
          <i class="fab fa-linkedin"></i>
        </a>
      </div>
    </div>
    <div class="woman-code">
      <img [src]="'../../../assets/code.png'" alt="woman-code" width="100%" />
    </div>
  </div>
</section>

<section style="padding: 4rem">
  <h4 class="title">Conhecimentos:</h4>
  <div class="skills">
    <div class="skills-content">
      <div class="progress">
        <div class="progress-bar">
          <span class="description">HTML5</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar">
          <span class="description">CSS</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 60%">
          <span class="description">SASS</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 70%">
          <span class="description">JavaScript</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 60%">
          <span class="description">TypeScript</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 70%">
          <span class="description">Angular</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 50%">
          <span class="description">NodeJS</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 40%">
          <span class="description">React</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 40%">
          <span class="description">Java</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 50%">
          <span class="description">Ionic</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 40%">
          <span class="description">React Native</span>
        </div>
      </div>
      <br>
      <div class="progress">
        <div class="progress-bar" style="width: 50%">
          <span class="description">Git e GitHub</span>
        </div>
      </div>
    </div>
    <div class="skills-img">
      <img [src]="'../../../assets/skills.png'" alt="skills" width="100%" />
    </div>
  </div>
</section>