<header>
  <nav class="desktop">
    <ul>
      <li>Início</li>
      <li>Sobre</li>
      <li>Projetos</li>
      <li>Contato</li>
    </ul>
  </nav>

  <div class="mobile">
    <span class="menu-trigger" (click)="toggle()">
      <i class="fas fa-bars"></i>
    </span>
    <nav class="sidebar" [ngClass]="menuOpen ? 'show' : 'hidden'">
      <ul>
        <li>Início</li>
        <li>Sobre</li>
        <li>Projetos</li>
        <li>Contato</li>
      </ul>
    </nav>
  </div>
</header>